import React from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useState } from 'react';
import axios from 'axios';
import { Button, Divider, Empty } from 'antd';

const HomePage = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadCategories();
  }, []);

  async function loadCategories() {
    let { data } = await axios.get('/category/categories');
    if (data.error) {
      toast.error(data.error);
    } else {
      setCategories(data);
    }
  }

  return (
    <div className='row mt-4  p-3 text-center'>
      <div className='col-md-12'>
        <div className='row mb-4 g-5'>
          {categories.length ? (
            categories?.map((val, idx) => {
              return (
                <div className='col-4' key={idx}>
                  <div className='border rounded shadow-sm w-100 p-3'>
                    <ul className='list-unstyled d-flex flex-column justify-content-center'>
                      <li className='text-start fs-6 fw-bolder'>
                        Fan nomi:{' '}
                        <span className='fw-semibold'>{val.name}</span>
                      </li>
                      <li className='text-start fs-6 fw-bolder'>
                        Savollar soni: <span className='fw-semibold'>30</span>
                      </li>
                      <li className='text-start fs-6 fw-bolder'>
                        Test tuzuvchi:{' '}
                        <span className='fw-semibold'>
                          {val.createdBy.lastName} {val.createdBy.name}
                        </span>
                      </li>
                    </ul>
                    <Divider />
                    <Button
                      onClick={() => navigate('/user/test/' + val._id)}
                      type='primary'
                      disabled={val.questionCount < 10}
                    >
                      Testni boshlash
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty description='FANLAR HALI KIRITILMAGAN' />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
