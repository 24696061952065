import React from 'react';
import { NavLink } from 'react-router-dom';

function UserMenu(props) {
  return (
    <div className='col-md-4 bg-light p-3 '>
      <ul>
        <li className='r_link p-2'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
            }
            to={'/user/profile'}
          >
            PROFILE
          </NavLink>
        </li>
        <li className='r_link p-2'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
            }
            to={'/user/rating'}
          >
            MENING NATIJALARIM
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default UserMenu;
