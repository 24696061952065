import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/auth';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Input, Modal } from 'antd';

function ModalComponent({
  isModalOpen,
  setIsModalOpen,
  loadCategories,
  selectedCategory,
}) {
  const [categoryName, setCategoryName] = useState('');

  useEffect(() => {
    if (selectedCategory?.name) {
      setCategoryName(selectedCategory.name);
    } else {
      setCategoryName('');
    }
  }, [isModalOpen]);

  async function handleEdit() {
    let { data } = await axios.put(
      '/category/edit-category',
      { name: categoryName, id: selectedCategory?._id },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      },
    );
    if (data.error) {
      toast.error(data.error);
    } else {
      setIsModalOpen(false);
      setCategoryName('');
      loadCategories();
      toast.success('Fan tahrirlandi!');
    }
  }

  async function onSaveNewCategory() {
    let { data } = await axios.post(
      '/category/new-category',
      { name: categoryName },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      },
    );
    if (!data.error) {
      setIsModalOpen(false);
      setCategoryName('');
      loadCategories();
      toast.success("Fan qo'shildi!");
    } else {
      toast.error(data.error);
    }
  }

  const handleSubmit = async () => {
    if (selectedCategory?._id) {
      await handleEdit();
    } else {
      await onSaveNewCategory();
    }
  };

  return (
    <>
      <Modal
        title='Basic Modal'
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={() => setIsModalOpen(false)}
      >
        <Input
          placeholder='Fan nomi'
          onChange={evt => setCategoryName(evt.target.value)}
          value={categoryName}
        />
      </Modal>
    </>
  );
}

export default ModalComponent;
