import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import { Button, Input, Skeleton } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/auth';

function TeacherProfile(props) {
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    let { data } = await axios.get(`/user/get-teacher`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
    });
    if (data.error) {
      toast.error(data.error);
    } else {
      setUser(data);
    }
    setLoading(false);
  };

  const editProfile = async e => {
    e.preventDefault();
    const { name, lastName, password, number } = e.target.elements;
    const request = {
      name: name.value,
      lastName: lastName.value,
      password: password.value,
      number: number.value,
    };
    let { data } = await axios.put(`/user/edit-user`, request);
    if (data.error) {
      console.log(data.error);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.setItem('user', JSON.stringify(data.userEdited));
      localStorage.setItem('token', JSON.stringify(data.access_token));
      loadUser();
      toast.success('Muvaffaqiyatli o`zgartirildi');
    }
  };

  if (!loading) {
    return (
      <div className='col-md-8'>
        <div className='row text-white'>
          <form
            onSubmit={editProfile}
            className='d-flex flex-column w-100 gap-3'
          >
            <Input
              disabled={!auth?.user?.isAllowed}
              placeholder='Ism'
              name='name'
              size='large'
              defaultValue={user?.name}
            />
            <Input
              disabled={!auth?.user?.isAllowed}
              placeholder='Familiya'
              name='lastName'
              size='large'
              defaultValue={user?.lastName}
            />
            <Input
              disabled
              placeholder='Login'
              name='login'
              size='large'
              defaultValue={user?.login}
            />
            <Input.Password
              disabled={!auth?.user?.isAllowed}
              placeholder='Parol'
              name='password'
              size='large'
            />
            <Input
              disabled={!auth?.user?.isAllowed}
              placeholder='Tel. raqam'
              name='number'
              size='large'
              defaultValue={user?.phoneNumber}
            />
            <Input
              disabled
              placeholder='Status'
              name='status'
              size='large'
              defaultValue={user?.status}
            />
            <div className=''>
              <Button
                type='primary'
                disabled={!auth?.user?.isAllowed}
                size='large'
                icon={<EditOutlined />}
                htmlType='submit'
              >
                Tahrirlash
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className='col-md-8 d-flex flex-column gap-3'>
        <Skeleton.Input active block />
        <Skeleton.Input active block />
        <Skeleton.Input active block />
        <Skeleton.Button active />
      </div>
    );
  }
}

export default TeacherProfile;
