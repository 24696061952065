import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Skeleton } from 'antd';
import axios from 'axios';
import { EditOutlined } from '@ant-design/icons';
import { useAuth } from '../../context/auth';
import UserMenu from '../../components/menu/UserMenu';
function UserProfile(props) {
  const [auth, setAuth] = useAuth();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    let { data } = await axios.get(`/user/get-user`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
    });
    if (data.error) {
      toast.error(data.error);
    } else {
      setUser(data);
    }
    setLoading(false);
  };

  const editUser = async e => {
    e.preventDefault();
    const { name, lastName, password, address, number } = e.target.elements;
    const request = {
      name: name.value,
      lastName: lastName.value,
      password: password.value,
      address: address.value,
      number: number.value,
    };
    let { data } = await axios.put('/user/edit-user', request);
    if (data.error) {
      toast.error(data.error);
    } else {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.setItem('user', JSON.stringify(data.userEdited));
      localStorage.setItem('token', JSON.stringify(data.access_token));
      loadUser();
      toast.success('Muvaffaqiyatli o`zgartirildi');
    }
  };

  return (
    <div className='row p-3'>
      <UserMenu />
      {!loading ? (
        <div className='col-md-8'>
          <div className='row text-white'>
            <form
              className='d-flex flex-column w-100 gap-2'
              onSubmit={editUser}
            >
              <Input
                placeholder='Ism'
                disabled={auth?.user?.isTeacher == 'true'}
                name='name'
                size='large'
                defaultValue={user?.name}
              />
              <Input
                placeholder='Familiya'
                disabled={auth?.user?.isTeacher == 'true'}
                name='lastName'
                size='large'
                defaultValue={user?.lastName}
              />
              <Input
                placeholder='Login'
                disabled={auth?.user?.isAllowed !== true}
                name='login'
                size='large'
                defaultValue={user?.login}
              />
              <Input.Password
                placeholder='Parol'
                disabled={auth?.user?.isTeacher == 'true'}
                name='password'
                size='large'
              />
              {/* <div className="col-md-12">
                            <input
                                type="text"
                                value={number}
                                disabled={auth?.user?.isTeacher == "true"}
                                onChange={(e) => setNumber(e.target.value)}
                                className="form-control mt-2 mb-2"
                            />
                        </div> */}
              {/* <div className="col-md-12">
                            <input
                                type="text"
                                value={status}
                                disabled={auth?.user?.isAllowed !== true}
                                onChange={(e) => setStatus(e.target.value)}
                                className="form-control mt-2 mb-2"
                            />
                        </div> */}
              <Input
                placeholder='Tel. raqam'
                disabled={auth?.user?.isTeacher == 'true'}
                name='number'
                size='large'
                defaultValue={user?.phoneNumber}
              />
              <Input
                placeholder='Manzil'
                disabled={auth?.user?.isTeacher == 'true'}
                name='address'
                size='large'
                defaultValue={user?.address}
              />
              <div className=''>
                <Button
                  type='primary'
                  size='large'
                  disabled={auth?.user?.isTeacher == true}
                  icon={<EditOutlined />}
                  htmlType='submit'
                >
                  Tahrirlash
                </Button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className='col-md-8 d-flex flex-column gap-3'>
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Input active block />
          <Skeleton.Button active />
        </div>
      )}
    </div>
  );
}

export default UserProfile;
