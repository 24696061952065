import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth';
import Login from '../pages/auth/Login/Login';
import Jumbotron from './Jumbotron';

function ProtectedRouteTeacher(props) {
  const [auth, setAuth] = useAuth();

  return (
    <>
      <Jumbotron />
      <Outlet />
    </>
  );
}

export default ProtectedRouteTeacher;
