import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table } from 'antd';
import TeacherMenu from '../../components/menu/TeacherMenu';
import { useAuth } from '../../context/auth';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ModalComponent from '../../components/Modal';
import toast from 'react-hot-toast';

const columns = (handleDelete, handleEdit) => [
  {
    title: 'No.',
    dataIndex: 'name',
    key: 'createdAt',
    width: '5%',
    render: (value, record, index) => <>{index + 1}</>,
  },
  {
    title: 'Fan Nomi',
    dataIndex: 'name',
  },
  {
    title: "Qo'shgan",
    render: value => (
      <>
        {value?.createdBy?.name} {value?.createdBy?.lastName}
      </>
    ),
  },
  {
    title: 'Sozlash',
    width: 300,
    render: value => (
      <>
        <Button
          onClick={() => handleEdit(value)}
          type='primary'
          icon={<EditOutlined />}
          className='me-2 bg-warning text-black'
        >
          Tahrirlash
        </Button>
        <Button
          onClick={() => handleDelete(value._id)}
          type='primary'
          icon={<DeleteOutlined />}
          danger
        >
          O'chirish
        </Button>
      </>
    ),
  },
];

function Category(props) {
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadCategory();
  }, []);

  async function handleDelete(id) {
    let { data } = await axios.post(
      `/category/delete-category/`,
      { id },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      },
    );
    if (data.error) {
      toast.error(data.error);
    } else {
      loadCategory();
      toast.success("Fan o'chirildi!");
    }
  }

  const handleEdit = category => {
    setCategory(category);
    setIsModalOpen(true);
  };

  const loadCategory = async () => {
    let { data } = await axios.get('/category/categories');
    if (!data.error) {
      setCategories(
        data.map(category => {
          return {
            ...category,
            key: category._id,
          };
        }),
      );
      setCategory({});
    }
  };

  return (
    <>
      <div className='row p-3'>
        <TeacherMenu />
        <div className='col-md-8'>
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <h2 className='fs-4 fw-semibold'>Fanlar</h2>
            <Button
              icon={<PlusOutlined />}
              type='primary'
              onClick={() => {
                setIsModalOpen(true);
                setCategory({});
              }}
            >
              Qo'shish
            </Button>
          </div>
          <Table
            columns={columns(handleDelete, handleEdit)}
            dataSource={categories}
          />
        </div>
      </div>
      <ModalComponent
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        loadCategories={loadCategory}
        selectedCategory={category}
      />
    </>
  );
}

export default Category;
