import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Register from './pages/auth/Register/Register';
import HomePage from './pages/HomePage';
import Login from './pages/auth/Login/Login';
import RandomTest from './pages/user/RandomTest';
import ProtectedRouteUser from './components/ProtectedRouteUser';
import ProtectedRouteTeacher from './components/ProtectedRouteTeacher';
import Kreteriya from './pages/teacher/Kreteriya';
import Category from './pages/teacher/Category';
import CreateQuestions from './pages/teacher/CreateQuestions';
import toast, { Toaster } from 'react-hot-toast';
import UserProfile from './pages/user/UserProfile';
import MyRating from './pages/user/MyRating';
import ProtectedRouteAdmin from './components/ProtectedRouteAdmin';
import AdminDashboard from './pages/admin/AdminDashboard';
import MainLayout from './layouts/MainLayout';
import { useAuth } from './context/auth';

function App() {
  const [auth, setAuth] = useAuth();
  return (
    <>
      <Routes>
        <Route
          path='/'
          element={auth?.token ? <MainLayout /> : <Navigate to='/auth' />}
        >
          <Route index element={<HomePage />} />
          <Route path='user' element={<ProtectedRouteUser />}>
            {/* <Route path='test/:categoryId' element={<RandomTest />} /> */}
            <Route path='profile' element={<UserProfile />} />
            <Route path='rating' element={<MyRating />} />
          </Route>
          <Route path='teacher' element={<ProtectedRouteTeacher />}>
            <Route path='dashboard' element={<Kreteriya />} />
            <Route path='category' element={<Category />} />
            <Route path='questions' element={<CreateQuestions />} />
          </Route>
          <Route path='admin' element={<ProtectedRouteAdmin />}>
            <Route path='dashboard' element={<AdminDashboard />} />
          </Route>
        </Route>
        <Route path='auth'>
          <Route
            index
            element={<Navigate to={auth?.token ? '/' : 'login'} />}
          />
          <Route
            path='login'
            element={auth?.token ? <Navigate to='/' /> : <Login />}
          />
          <Route
            path='register'
            element={auth?.token ? <Navigate to='/' /> : <Register />}
          />
        </Route>
        <Route path='/user/test/:categoryId' element={<RandomTest />} />
      </Routes>
      <Toaster />
    </>
  );
}

export default App;
