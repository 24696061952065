import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from '../../context/auth';
import axios from 'axios';
import UserMenu from '../../components/menu/UserMenu';
import { Empty, Table } from 'antd';
import { formatDate } from '../../utils';

const columns = [
  {
    title: 'No.',
    dataIndex: 'name',
    key: 'createdAt',
    width: '5%',
    render: (value, record, index) => <>{index + 1}</>,
  },
  {
    title: 'F.I.O.',
    render: value => (
      <>
        {value?.candidat?.lastName} {value?.candidat?.name}
      </>
    ),
  },
  {
    title: 'Fan',
    render: value => <>{value?.categoryId?.name}</>,
  },
  {
    title: 'Ball',
    dataIndex: 'rating',
  },
  {
    title: 'Tel. raqam',
    dataIndex: 'number',
  },
  {
    title: 'Sana',
    render: value => <>{formatDate(value?.createdAt)}</>,
  },
];

function MyRating() {
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    loadRating();
  }, []);

  const loadRating = async () => {
    let { data } = await axios.get(`/user/get-user/rating`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
    });
    if (data.error) {
      toast.error(data.error);
    } else {
      setRatings(
        data.map(el => {
          return {
            ...el,
            key: el._id,
          };
        }),
      );
    }
  };

  return (
    <div className='row p-3'>
      <UserMenu />
      <div className='col-md-8'>
        <div>
          {ratings.length ? (
            <Table columns={columns} dataSource={ratings} />
          ) : (
            <Empty description='Natija mavjud emas' />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyRating;
