import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Button, Input, Radio, Select } from 'antd';
import { useAuth } from '../../../context/auth';

import './Register.css';

function Register(props) {
  const [auth, setAuth] = useAuth();
  const [status, setStatus] = useState('student');
  const [categories, setCategories] = useState([]);
  const [isTeacher, setIsTeacher] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    loadCategories();
  }, []);

  async function loadCategories() {
    let { data } = await axios.get('/category/categories');
    if (data.error) {
      toast.error(data.error);
    } else {
      if (Array.isArray(data)) {
        setCategories(
          data.map(el => {
            return { value: el.name, label: el.name };
          }),
        );
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const { name, lastName, login, password } = e.target.elements;
    const request = {
      name: name.value,
      lastName: lastName.value,
      login: login.value,
      password: password.value,
      isTeacher: isTeacher ? isTeacher : 'false',
      status,
    };
    if (
      request?.name &&
      request?.lastName &&
      request?.login &&
      request?.password
    ) {
      let { data } = await axios.post('/register', request);
      if (data.error) {
        toast.error(data.error);
      } else {
        toast.success("Siz ro'yxatdan o'tdingiz!");
        localStorage.setItem('user', JSON.stringify(data.userCreated));
        localStorage.setItem('token', JSON.stringify(data.access_token));
        setAuth({
          user: data.userCreated,
          token: data.access_token,
        });
        navigate('/');
      }
    } else {
      toast.error('Barcha maydonlar toldirilishi shart!');
    }
  };
  return (
    <>
      <div className='register-bg'></div>
      <div className='register-content w-100 d-flex justify-content-center'>
        <div className='bg-light rounded shadow w-25'>
          <form className='p-3' onSubmit={handleSubmit}>
            <h2 className='mb-3 fw-semibold fs-3'>Ro'yxatdan o'tish</h2>
            <Input
              className='mb-3'
              placeholder='ISM'
              size='large'
              name='name'
            />
            <Input
              className='mb-3'
              placeholder='FAMILIYA'
              size='large'
              name='lastName'
            />
            <Input
              className='mb-3'
              placeholder='LOGIN'
              size='large'
              name='login'
            />
            {status == 'teacher' && (
              <Select
                className='w-100 mb-3'
                options={categories}
                name='isTeacher'
                size='large'
                placeholder='FAN NOMI'
                onChange={evt => setIsTeacher(evt)}
              />
            )}
            <Input.Password
              placeholder='PAROL'
              size='large'
              name='password'
              className='mb-3'
            />
            <Radio.Group
              onChange={evt => setStatus(evt.target.value)}
              value={status}
            >
              <Radio value='teacher'>O'QITUVCHI</Radio>
              <Radio value='student'>TALABA</Radio>
            </Radio.Group>
            <div className='d-flex justify-content-center'>
              <Button
                htmlType='submit'
                type='primary'
                size='large'
                className='mt-3'
              >
                Ro'yxatdan o'tish
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;
