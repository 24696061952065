import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Layout, Menu, Space, Tag } from 'antd';
import {
  AppstoreOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth } from '../context/auth';
import axios from 'axios';
import toast from 'react-hot-toast';

const { Header, Sider, Content } = Layout;

const menuItems = [
  {
    key: '/',
    icon: <HomeOutlined />,
    label: 'Asosiy',
  },
  {
    key: '/user/profile',
    icon: <AppstoreOutlined />,
    label: 'Dashboard',
    teacherRoute: '/teacher/dashboard',
  },
];

function Navbar({ children }) {
  const [auth, setAuth] = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState({});

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    let { data } = await axios.get(`/user/get-user`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('token')),
      },
    });
    if (data.error) {
      toast.error(data.error);
    } else {
      setUser(data);
    }
  };

  useEffect(() => {
    const { pathname } = location;
    const matchedKey = getMenuKeyByPath(pathname);
    setSelectedKeys([matchedKey]);
  }, [location]);

  const getMenuKeyByPath = path => {
    switch (path) {
      case '/':
        return '/';
      default:
        return auth?.user?.status != 'student'
          ? '/teacher/dashboard'
          : '/user/profile';
    }
  };

  const handleLogOut = () => {
    setAuth('');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    navigate('/auth');
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div
          style={{ paddingLeft: 24 }}
          className='demo-logo-vertical fs-5 fw-semibold py-3 text-light'
        >
          {collapsed ? ' ' : 'ONLINE TEST'}
        </div>
        <Menu theme='dark' mode='inline' selectedKeys={selectedKeys}>
          {menuItems.map(item => (
            <Menu.Item
              key={
                auth?.user?.status != 'student'
                  ? item?.teacherRoute || item.key
                  : item.key
              }
              icon={item.icon}
            >
              <NavLink
                className='text-decoration-none'
                to={
                  auth?.user?.status != 'student'
                    ? item?.teacherRoute || item.key
                    : item.key
                }
              >
                {item.label}
              </NavLink>
            </Menu.Item>
          ))}
          <Menu.Item
            key={Date.now()}
            onClick={handleLogOut}
            icon={<LogoutOutlined />}
          >
            Chiqish
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className='p-0 bg-light d-flex align-items-center justify-content-between border-bottom'>
          <Button
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Space className='me-5'>
            <Tag color={user?.isAllowed ? 'success' : 'error'}>
              {user?.isAllowed ? 'Faol' : 'Faollashtirilmagan'}
            </Tag>
            <Avatar shape='square' size='large' icon={<UserOutlined />} />
            <div className='d-flex flex-column'>
              <span className='fw-semibold lh-sm'>{user?.name}</span>
              <span className='fw-semibold lh-1'>{user?.lastName}</span>
            </div>
          </Space>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: 'white',
            overflowY: 'auto',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

export default Navbar;

/*<nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid p-3">
                <a className="navbar-brand" href="/">ONLINE TEST</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <span>
                        <ul className="navbar-nav me-auto mb-2 w-100 mb-lg-0 d-flex justify-content-around " >
                            <li className='nav-item'>
                                <NavLink className='nav-link' to={'/'}>
                                    ASOSIY
                                </NavLink>
                            </li>
                            {
                                !auth.user ?
                                    <>
                                        <li className='nav-item'>
                                            <NavLink className='nav-link' to={'/register'}>
                                                REGISTER
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink className='nav-link' to={'/login'}>
                                                LOGIN
                                            </NavLink>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li className="nav-item dropdown navbar-nav">
                                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {
                                                    auth?.user?.name
                                                }
                                            </a>
                                            <ul className="dr_down dropdown-menu" >

                                                <Link to={auth?.user?.status != "student" ? '/teacher/dashboard' : '/user/profile'}>
                                                    <li className="dropdown-item" style={{ cursor: "pointer" }}>
                                                        DASHBOARD
                                                    </li>
                                                </Link>
                                                
                                                <li className="dropdown-item" onClick={() => {
                                                    setAuth('');
                                                    localStorage.removeItem('user');
                                                    localStorage.removeItem('token');
                                                }} style={{ cursor: "pointer" }}>LOGOUT</li>
                                            </ul>
                                        </li>
                                    </>
                            }
                        </ul>
                    </span>

                </div>
            </div>
        </nav>*/
