import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../../context/auth';

function TeacherMenu(props) {
  const [auth, setAuth] = useAuth();
  return (
    <div className='col-md-3 bg-light p-3 '>
      <ul>
        <li className='r_link p-2'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
            }
            to={'/teacher/dashboard'}
          >
            PROFILE
          </NavLink>
        </li>
        <li className='r_link p-2'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
            }
            to={'/teacher/category'}
          >
            FAN KATEGORIYASI
          </NavLink>
        </li>
        <li className='r_link p-2'>
          <NavLink
            className={({ isActive }) =>
              isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
            }
            to={'/teacher/questions'}
          >
            FAN TESTLAR
          </NavLink>
        </li>
        {auth?.user?.isAdmin && (
          <li className='r_link p-2'>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'btn btn-primary' : 'btn btn-outline-primary'
              }
              to={'/admin/dashboard'}
            >
              UMUMIY BOSHQARUV
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}

export default TeacherMenu;
