import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { useAuth } from '../../context/auth';
import { Button, Divider, Empty } from 'antd';
import { RightOutlined, CheckCircleOutlined } from '@ant-design/icons';

function RandomTest(props) {
  const [auth, setAuth] = useAuth();

  // states

  const [rating, setRating] = useState({ parentId: '', ratings: [] });
  const [rating1, setRating1] = useState([]);
  const [test12, setTest12] = useState([]);

  const [ratingStorage, setRatingStorage] = useState([]);

  const [tests1, setTests1] = useState([]);
  const [order, setOrder] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // hooks
  const { categoryId } = useParams();

  useEffect(() => {
    loadQuestions();
  }, []);

  async function loadQuestions() {
    let { data } = await axios.get(`/question/get-question/${categoryId}`);
    if (data.error) {
      toast.error(data.error);
    } else {
      setTests1(data);
    }
  }

  const navigate = useNavigate();
  // hard code

  // function
  // unordered array
  const handleNext = () => {
    let checkRate = rating.ratings.filter(s => s.rate);
    if (checkRate.length == 3) {
      localStorage.setItem(
        'ratings',
        JSON.stringify({
          ratings: [...ratingStorage, { parentId: rating.parentId, rate: 5 }],
        }),
      );
      setOrder(order + 1);
      setRating({ parentId: '', ratings: [] });
      setRatingStorage(JSON.parse(localStorage.getItem('ratings')).ratings);
    } else if (checkRate.length == 2) {
      localStorage.setItem(
        'ratings',
        JSON.stringify({
          ratings: [...ratingStorage, { parentId: rating.parentId, rate: 4 }],
        }),
      );
      setOrder(order + 1);
      setRating({ parentId: '', ratings: [] });
      setRatingStorage(JSON.parse(localStorage.getItem('ratings')).ratings);
    } else if (checkRate.length == 1) {
      localStorage.setItem(
        'ratings',
        JSON.stringify({
          ratings: [...ratingStorage, { parentId: rating.parentId, rate: 3 }],
        }),
      );
      setOrder(order + 1);
      setRating({ parentId: '', ratings: [] });
      setRatingStorage(JSON.parse(localStorage.getItem('ratings')).ratings);
    } else {
      localStorage.setItem(
        'ratings',
        JSON.stringify({
          ratings: [...ratingStorage, { parentId: rating.parentId, rate: 0 }],
        }),
      );
      setOrder(order + 1);
      setRating({ parentId: '', ratings: [] });
      setRatingStorage(JSON.parse(localStorage.getItem('ratings')).ratings);
    }
    setRating1([]);
  };
  // get Rate
  function result() {
    let { ratings } = JSON.parse(localStorage.getItem('ratings'));
    let box = 0;
    ratings.map((val, idx) => {
      box += parseInt(val.rate);
    });
    let persantege = 10 * 5;
    let result = 100 / (persantege / box);
    setRatingStorage({ rate: result });
    setIsOpen(!isOpen);
  }
  // confirmRate
  async function confirmRate() {
    let rating = 0;
    if (ratingStorage.rate >= 56 && ratingStorage.rate <= 70) {
      rating = 3;
    } else if (ratingStorage.rate >= 71 && ratingStorage.rate <= 85) {
      rating = 4;
    } else if (ratingStorage.rate >= 86 && ratingStorage.rate <= 100) {
      rating = 5;
    }
    let { data } = await axios.post(
      '/user/confirm-rate',
      { rate: rating, subjectId: categoryId, number: auth?.user?.number },
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem('token')),
        },
      },
    );
    if (data.error) {
      toast.error(data.error);
    } else {
      toast.success('Muvaffaqiyatli tasdiqlandi');
    }
    localStorage.removeItem('ratings');
    navigate('/');
  }

  return (
    <>
      {tests1.length >= 10 ? (
        <div className='container mt-5'>
          <div className='row'>
            {auth?.user?.number != 'number' ? (
              order < 10 && order < tests1.length ? (
                tests1.map((val, idx) => {
                  if (idx == order) {
                    return (
                      <div className='col-md-12' key={idx}>
                        <div className='row g-4 mb-3'>
                          <div className='col-md-12'>
                            <h3 className='fs-4 fw-semibold'>{val?.matn}</h3>
                          </div>
                          <Divider />
                          {val?.tests?.map((value, indx) => {
                            return (
                              <div className='col-4' key={indx}>
                                <div className='border rounded shadow-sm p-3'>
                                  <h3 className='fs-5 fw-semibold m-0'>
                                    {value?.savol}
                                  </h3>
                                  <Divider />
                                  <ul className='list-unstyled d-flex flex-column gap-3 w-100'>
                                    {value?.variantlar?.map((test, index) => {
                                      return (
                                        <li className='w-100' key={index}>
                                          <Button
                                            disabled={rating1.includes(
                                              value._id,
                                            )}
                                            onClick={() => {
                                              setRating1([
                                                ...rating1,
                                                value._id,
                                              ]);
                                              setTest12([...test12, val?.matn]);
                                              setRating({
                                                parentId: val._id,
                                                ratings: [
                                                  ...rating.ratings,
                                                  {
                                                    id: value._id,
                                                    rate: test?.isTrue,
                                                  },
                                                ],
                                              });
                                            }}
                                            className='w-100 text-start'
                                          >
                                            {test?.qiymat}
                                          </Button>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <>
                  <div className='row'>
                    <div className='col-md-12 text-center'>
                      <h3 className='text-center'>
                        {!tests1.length
                          ? 'Savollar mavjud emas'
                          : 'Savollar yakunlandi'}
                      </h3>
                      <Button
                        size='large'
                        type='primary'
                        onClick={result}
                        disabled={!tests1.length}
                      >
                        Umumiy natija
                      </Button>
                    </div>
                  </div>
                  {isOpen && (
                    <div>
                      <p>Umumiy natija {ratingStorage.rate} %</p>
                      <Button
                        type='primary'
                        size='large'
                        danger
                        onClick={confirmRate}
                      >
                        Tasdiqlash
                      </Button>
                    </div>
                  )}
                </>
              )
            ) : (
              <>
                <h3 className='text-center text-black'>
                  Avval telefon nomeringizni kiriting!
                </h3>
                <div className='text-center'>
                  <Button
                    type='primary'
                    size='large'
                    danger
                    onClick={() => navigate('/user/profile')}
                  >
                    KIRITISH
                  </Button>
                </div>
              </>
            )}

            {/* tartib raqamini hisoblab borish */}
            {order < 10 && auth?.user?.number != 'number' && (
              <div className='col-md-12 p-3 mb-4'>
                <div className='d-flex align-items-center justify-content-around'>
                  <div className='d-flex justify-content-center'>
                    <p className='fs-5 fw-bolder m-0'> Savol: {order + 1}/10</p>
                  </div>
                  <div className='text-center'>
                    <Button
                      icon={<RightOutlined />}
                      size='large'
                      type='primary'
                      onClick={handleNext}
                      disabled={rating?.ratings?.length < 3}
                    >
                      Keyingisi
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Empty description="Savollar to'liq emas" />
      )}
    </>
  );
}

export default RandomTest;
