import { Button, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

const columns = (deleteUser, editUser) => [
  {
    title: 'No.',
    key: 'createdAt',
    width: '5%',
    render: (value, record, index) => <>{index + 1}</>,
  },
  {
    title: 'Ism',
    dataIndex: 'name',
  },
  {
    title: 'Familiya',
    dataIndex: 'lastName',
  },
  {
    title: 'Tel. raqam',
    dataIndex: 'phoneNumber',
  },
  {
    title: 'Fan',
    render: value => (
      <>{value?.isTeacher == 'false' ? 'talaba' : value?.isTeacher}</>
    ),
  },
  {
    title: 'Ruxsat',
    render: value => <>{value.isAllowed ? 'Ruxsat etilgan' : 'Man etilgan'}</>,
  },
  {
    title: 'Sozlash',
    render: value => (
      <>
        <Button
          onClick={() => editUser(value._id, !value.isAllowed)}
          type='primary'
          icon={<EditOutlined />}
          className='me-2 bg-warning text-black'
        >
          {value.isAllowed ? 'Taqiqlash' : 'Ruxsat berish'}
        </Button>
        <Button
          onClick={() => deleteUser(value._id)}
          type='primary'
          icon={<DeleteOutlined />}
          danger
        >
          O'chirish
        </Button>
      </>
    ),
  },
];

function UsersTable(props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      let { data } = await axios.get('/user/admin/get-users');
      if (data.error) {
        toast.error(data.error);
      } else {
        setUsers(
          data.map(user => {
            return {
              ...user,
              key: user._id,
            };
          }),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteItem = async id => {
    let { data } = await axios.delete(`/user/admin/user-delete/${id}`);
    if (data.error) {
      toast.error(data.error);
    } else {
      toast.success("Muvaffaqiyatli o'chirildi");
      loadUsers();
    }
  };
  const editItem = async (id, isAllowed) => {
    let { data } = await axios.put(`/user/admin/user-edit/${id}`, {
      isAllowed,
    });
    if (data.error) {
      toast.error(data.error);
    } else {
      toast.success('Muvaffaqiyatli bajarildi');
      console.log(data);
      loadUsers();
    }
  };

  return (
    <div className='col-md-8 table_srcoll'>
      <Table columns={columns(deleteItem, editItem)} dataSource={users} />
    </div>
  );
}

export default UsersTable;
