import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { useAuth } from '../../../context/auth';
import './Login.css';

function Login(props) {
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    let { login, password } = e.target.elements;
    const request = {
      login: login.value,
      password: password.value,
    };
    if (request?.login && request?.password) {
      try {
        setLoading(true);
        let { data } = await axios.post('/login', request);
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.success('Siz tizimga kirdingiz!');
          localStorage.setItem('user', JSON.stringify(data.userLoggedIn));
          localStorage.setItem('token', JSON.stringify(data.access_token));
          setAuth({
            user: data.userLoggedIn,
            token: data.access_token,
          });
          navigate('/');
          e.target.reset();
        }
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Hamma maydonlar to'ldirilishi shart!");
    }
  };
  return (
    <>
      <div className='login-bg'></div>
      <div className='login-content w-100 d-flex justify-content-center'>
        <div className='bg-light rounded w-25 shadow'>
          <form className='p-3' onSubmit={handleSubmit}>
            <h2 className='mb-3 fw-semibold fs-3'>Tizimga kirish</h2>
            <Input
              className='mb-3'
              placeholder='LOGIN'
              size='large'
              name='login'
            />
            <Input.Password placeholder='PAROL' size='large' name='password' />
            <Link to='/auth/register'>Ro'yxatdan o'tish</Link>
            <div className='d-flex justify-content-center'>
              <Button
                loading={loading}
                htmlType='submit'
                type='primary'
                size='large'
                className='mt-3'
              >
                KIRISH
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Login;
